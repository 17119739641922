@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: small;
}

table tr td{
  font-size: small;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


li {
  display: flex;
}

li span {
  flex: 1;
  padding: 0.5rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensures the container takes full width */
}

nav {
 margin-right: 2em;
}
nav ul {
  display: flex;
  gap: 10px; /* Adds space between the navigation items */
}

nav li {
  list-style-type: none; /* Removes the bullet points from list items */
}

nav a {
  display: block;
  padding: 3px;
  border-radius: 5px;
  text-decoration: none; /* Removes underline from links */
  color: white; /* Text color */
}

nav a:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}

.content {
  height: calc(100vh - 60px);
}